import * as React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    gap: var(--space-6);
    flex-wrap: wrap;
`

const TextContainer = styled.div`
    flex-basis: 400px;
    flex-grow: 1;

    h2 {
        margin: 0;
    }
`

const ImageContainer = styled.div`
    flex-basis: 250px;
    flex-grow: .9;
    
    img {
        border-radius: ${props => props.rounded ? "50%" : "initial"};
    }
`

function TxtImg(props) {

    return (
        <FlexContainer reverse={props.reverse}>
            <TextContainer>
                {props.children}
            </TextContainer>
            <ImageContainer rounded={props.rounded}>
                <GatsbyImage image={props.image} alt={props.alt} />
            </ImageContainer>
        </FlexContainer>
    )
}

TxtImg.propTypes = {
    rounded: PropTypes.bool
}

TxtImg.defaultProps = {
    rounded: true
}

export default TxtImg