import { graphql } from "gatsby"
import * as React from "react"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout/Layout"
import TxtImg from "../components/TxtImg/TxtImg"
import IconTile from "../components/IconTile/IconTile"
import { faRoute, faPaintBrush, faLaptopCode, faSearchPlus, faTools, faChartLine } from "@fortawesome/free-solid-svg-icons"
import Testimonial from "../components/Testimonial/Testimonial"
import ContactCTA from "../components/ContactCTA/ContactCTA"
import Button from "../components/Button/Button"
import { Helmet } from "react-helmet"

function Services({ data }) {
    const devicesImage = getImage(data.devices)
    const paIcon = getImage(data.paIcon)
    const contactImage = getImage(data.contactImage)
    return (
        <Layout pageTitle="Services">
            <Helmet>
                <meta name="description" content="From digital strategy to SEO, from web design & development to testing, analysis & improvement, there's a lot that goes into making an outstanding website." />
            
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://goddard.digital/services/" />
                <meta property="og:title" content="Services | Goddard Digital - Websites that deliver results" />
                <meta property="og:description" content="From digital strategy to SEO, from web design & development to testing, analysis & improvement, there's a lot that goes into making an outstanding website." />
                <meta property="og:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />
            
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://goddard.digital/services/" />
                <meta name="twitter:title" content="Services | Goddard Digital - Websites that deliver results" />
                <meta name="twitter:description" content="From digital strategy to SEO, from web design & development to testing, analysis & improvement, there's a lot that goes into making an outstanding website." />
                <meta name="twitter:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />
            </Helmet>
            <section>
                <div style={{width: 'min(90%, 1200px)', margin: 'auto'}}>
                    <TxtImg
                        image={devicesImage}
                        alt="Web devices illustration"
                    >
                        <h2>Making expert knowledge available to you.</h2>
                        <p>Creating a website is easy. Creating a website that delivers results for your business is hard. It's the knowledge and experience of experts that make the difference.</p>
                        <p>Here's what goes into creating outstanding websites that are focused on your objectives as well as what your customers and clients need.</p>
                    </TxtImg>
                </div>
            </section>
            <section>
                <div style={{width: 'min(90%, 1400px)', margin: 'auto', display: 'flex', flexWrap: 'wrap', gap: 'var(--space-6)'}}>
                    <IconTile icon={faRoute}>
                        <h3>Digital Strategy</h3>
                        <p>No project should start without a clear understanding of what it's supposed to achieve. We'll work together to ensure your digital activity is perfectly aligned with your business goals.</p>
                        <p>With many years of experience working on web projects with businesses of every size, you'll have the help you need shape the best solution to deliver on what you want to achieve.</p>
                    </IconTile>
                    <IconTile icon={faPaintBrush}>
                        <h3>Web Design</h3>
                        <p>Great design means more than just looking good – it’s finding the sweet spot between aesthetics, business goals and user experience. It means designing what’ll deliver results.</p>
                        <p>Designing mobile, tablet & desktop screens together ensures users on any device will all get a great design and a great experience, which is essential now that over half of web traffic is on mobile.</p>
                    </IconTile>
                    <IconTile icon={faLaptopCode}>
                        <h3>Web Development</h3>
                        <p>If you want your website to outperform your competition, it should be lightning-fast, responsive and future-proof whilst looking great and being easy for your customers or clients to use.</p>
                        <p>You can be confident of a site that loads in a flash and is created with code that's easy to maintain, making it a great platform to build upon so your website can grow with your business.</p>
                    </IconTile>
                    <IconTile icon={faSearchPlus}>
                        <h3>Search Engine Optimisation</h3>
                        <p>When looking to reach an online audience, search engine optimisation (SEO) is a key factor. I consider this at every step of a website project to ensure technical and content excellence.</p>
                        <p>Without ensuring your website is technically excellent and has great content that's well structured, it's all too easy to end up with a great looking site that nobody can find.</p>
                    </IconTile>
                    <IconTile icon={faTools}>
                        <h3>Website Testing</h3>
                        <p>I take a meticulous approach to testing every aspect of a website, using multiple approaches and tools so that nothing is left to chance in ensuring that quality is of the highest standard.</p>
                        <p>I use tools to help carry out testing across a wide range of browsers and operating systems and I use automated testing to enable you to make updates to your site with confidence.</p>
                    </IconTile>
                    <IconTile icon={faChartLine}>
                        <h3>Analysis & Improvement</h3>
                        <p>It's important to make the most of your investment. I offer packages to maintain your site, analyse how it's performing and recommend incremental improvements to get better results.</p>
                        <p>You'll also get peace of mind in knowing that your site is being actively monitored for reliability, security and software updates - let me worry about that stuff so you don't have to!</p>
                    </IconTile>
                </div>
            </section>
            <section>
                <Testimonial image={paIcon} name="Paula Abbey" business="Paula Abbey Counselling & Psychotherapy">
                    <p>Gideon took the time to find out exactly what it was that I was looking for, even though I was often unsure myself! Throughout the process, I have been involved and kept well informed of progress.</p>
                    <p>Gideon is incredibly patient and makes it easy for me to ask ‘stupid’ questions. I would recommend Gideon’s services to anyone, especially those who are somewhat of a technophobe!</p>
                </Testimonial>
            </section>
            <section>
                <ContactCTA image={contactImage} alt="Illustration showing a discussion around a computer screen">
                    <p>My background of over a decade working on web projects for growing small businesses to some of the world's biggest brands gives me the expertise to not only create you a website, but to make sure we create <strong style={{color: 'var(--secondary-900)'}}>the right website to deliver results</strong> for your business.</p>
                    <Button link={true} linkTo="/contact/">Let's get started</Button>
                </ContactCTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        devices: file(relativePath: {eq: "web-devices.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 1000
                    placeholder: BLURRED
                )
            }
        }
        paIcon: file(relativePath: {eq: "paula-abbey-icon.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 100
                    placeholder: BLURRED
                )
            }
        }
        contactImage: file(relativePath: {eq: "in-office.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Services